<template>
  <div class="mt-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch()">
        <b-card class="filter">
          <b-row>
            <div class="form-group col-md-3">
              <div>
                <b-button-toolbar>
                  <b-button-group>
                    <base-input
                      name="Anagrafica"
                      label="Anagrafica"
                      v-model="form.input_label_registry"
                      :readonly="true"
                    />
                    <b-button
                      class="mt-4 btn-quick"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      title="Ricerca Veloce"
                      @click="openQuickSearchRegistry"
                      ><b-icon icon="search"></b-icon
                    ></b-button>
                    <b-button
                      v-if="form.input_label_registry"
                      class="mt-4 btn-quick ml-1"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      @click="resetRegistryId"
                      title="Elimina Anagrafica"
                      ><b-icon icon="trash"></b-icon
                    ></b-button>
                  </b-button-group>
                </b-button-toolbar>
              </div>
            </div>
            <quick-search-registry-modal
              ref="quickSearchRegistry"
              @input="handleRegistryModalInput"
            >
            </quick-search-registry-modal>
            <div class="form-group col-md-3">
              <span
                v-if="registry_data"
                class="info"
                v-html="toInfoData(registry_data, 'registry')"
              >
              </span>
            </div>
            <!-- una volta scelto il registry, di fianco deve comparire:
            - o una tendina con i gruppi, con preselezionato gruppo principale (dipende da parametro di agenzia)
            - o una tendina in multiselect con tutte le relazioni (di parentela) di quel registry -->
            <div class="col-md-3" v-if="showGroups">
              <base-select
                name="groups-select"
                label="Gruppi"
                :options="opt_groups"
                v-model="filter.byRegistryGroup.id"
                @select="onSelectRegistryGroup"
              />
            </div>
            <div class="col-md-3" v-if="showRelations">
              <base-select
                name="relations-multiselect"
                label="Relazioni"
                :options="opt_relations"
                :multiple="true"
                v-model="byRegistryRegistry.id"
                @select="onSelectRegistryRegistry"
              />
              <!-- il problema è che il v-model "filter.byRegistry.id" è lo stesso nei 2 casi in OR ESCLUSIVO -->
              <!-- in appoggio su "byRegistryRegistry", per poi convertirlo opportunamente nella sumbit -->
            </div>
          </b-row>
          <b-row>
            <div class="col-md-2">
              <base-month-picker
                id="to"
                vid="periodo_a"
                name="periodo_a"
                label="Escludi quietanzamenti con scadenza a partire dal"
                v-model="filter.byCalendar.to"
                :clearable="true"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                :disabled="!filter.byRegistry.id"
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter()"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>
    <div class="mt-2">
      <div>
        <span
          v-html="
            isLoading && items.length
              ? 'Elaborazione in corso, attendere...'
              : ''
          "
        ></span>
        <b-overlay center :show="isLoading" rounded="sm">
          <div v-if="!isLoading">
            <div class="mt-1">
              <b-button
                v-show="Object.keys(model).length"
                type="button"
                :pressed.sync="myToggle"
                variant="light"
                class="mb-2"
                size="sm"
                @click="onToggleSelectAll"
                :disabled="isToggling"
              >
                <b-spinner small type="grow" v-show="isToggling"></b-spinner>
                {{ myToggle ? "Deseleziona" : "Seleziona" }} tutti</b-button
              >
            </div>
            <sesana
              :fields="fields"
              :items="items"
              @open_note="openNoteModal"
              @check="onSelectRow"
              @submit="onSubmit"
              v-model="model"
              :semColor="toSemColor"
              :elaColor="toElaColor"
            >
            </sesana>
            <b-row class="table-extra-footer">
              <p class="title">Da Incassare:</p>
              <div class="sem-green"></div>
              <p class="label">Recuperabile Totalmente</p>
              <div class="sem-yellow"></div>
              <p class="label">Recuperabile Parzialmente</p>
              <div class="sem-red"></div>
              <p class="label">Non Recuperabile</p>
            </b-row>
            <b-row class="table-extra-footer">
              <p class="title">Selezione:</p>
              <div class="check-green"></div>
              <p class="label">Acconto</p>
              <div class="check-red"></div>
              <p class="label">Sospeso</p>
            </b-row>
            <div class="table-extra-footer">
              <b-button
                type="button"
                variant="lisaweb"
                class="mt-2"
                size="sm"
                @click="submit()"
                >Recupera</b-button
              >
            </div>
            <note-detail-modal :items="notes"> </note-detail-modal>
          </div>
          <template #overlay>
            <div class="text-center mt-5">
              <base-icon name="loading" width="35" height="35" />
              <p id="cancel-label">Operazione in corso...</p>
            </div>
          </template>
        </b-overlay>
      </div>
    </div>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseMonthPicker from "@/components/form/BaseMonthPicker";
import NoteDetailModal from "@/components/modals/noteDetailModal";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { prepareFilterQueryString } from "@/utils/forms";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate, fromLastDayPreviousMonth } from "@/utils/dates";
import { toInfoData, toRelationString } from "@/utils/transforms";
import { mergeIndex } from "@/utils/advance-payments";
import common from "@/components/accounting/advance-payment/common";

export default {
  extends: common,
  name: "sesana-clienti",
  data() {
    return {
      isLoading: false,
      notes: [],
      items: [],
      items1: [],
      items2: [],
      items3: [],
      items4: [],
      selectedNegRows: [],
      selectedPosRows: [],
      negIds: [],
      posIds: [],
      sem: {}, // semaphores for deferreds
      ela: {}, // elaborated accounts
      filter: this.initFilter(),
      showGroups: false,
      showRelations: false,
      byRegistryRegistry: {
        id: null,
      },
      registry_data: null,
      registry_group_data: null,
      form: {
        input_label_registry_group: "",
        input_label_registry: "",
        quick_value: "",
      },
      table: {
        filterOn: {
          byRelations: [
            // "byBroker",
            "byRegistry",
            // "byInsurer",
            "byInsuranceAncillary",
            "byVariousAccounting",
            "byTask",
          ],
        },
      },
      resource: "book_entries",
      repository: "book_entry",
      opt_groups: [],
      opt_relations: [],
      fields: [
        {
          key: "type",
          label: "Tipo",
          thClass: "xxxsw",
        },
        {
          key: "book_date",
          label: this.getDictionary("book_date", "book_entry"),
          formatter: (value) => toLocaleDate(value),
          thClass: "sw",
        },
        {
          key: "insurance_policy_number",
          label: this.getDictionary("insurance_policy_number", "book_entry"),
          thClass: "mw",
        },
        {
          key: "registry",
          label: this.getDictionary("registry", "book_entry"),
          thClass: "lw",
        },
        {
          key: "check1",
          type: "checkbox",
          label: this.getDictionary("Sel."),
          thClass: "check zi2",
          tdClass: "check",
        },
        {
          key: "gross",
          label: this.getDictionary("gross", "book_entry"),
          formatter: (value) => toLocaleCurrency(value),
          thClass: "sw text-right",
          tdClass: "text-right",
        },
        {
          key: "money",
          label: this.getDictionary("Da Incassare"),
          formatter: (value) => toLocaleCurrency(value),
          thClass: "sw text-right",
          tdClass: "text-right",
        },
        {
          key: "note_counter",
          label: this.getDictionary("Note"),
          thClass: "xxxsw text-right",
          tdClass: "float-right",
        },
      ],
      model: {},
      myToggle: false, // #943: select/deselect all checkboxes by button
      isToggling: false,
    };
  },
  components: {
    BaseIcon,
    QuickSearchRegistryModal,
    BaseInput,
    BaseSelect,
    BaseMonthPicker,
    NoteDetailModal,
  },
  methods: {
    toInfoData,
    initFilter() {
      let init = {
        byAttribute: {
          title: null,
        },
        byRegistry: {
          id: null,
        },
        byRegistryGroup: {
          id: null,
        },
        byCalendar: {
          from: null,
          to: null,
        },
      };
      return init;
    },
    openNoteModal(array) {
      this.notes = array;
      this.$bvModal.show("noteDetailModal");
    },
    onSelectRegistryGroup(selected) {
      // deseleziono l'altro
      if (selected) {
        this.$set(this.byRegistryRegistry, "id", null);
      }
    },
    onSelectRegistryRegistry(selected) {
      // deseleziono l'altro
      if (selected) {
        this.$set(this.filter.byRegistryGroup, "id", null);
      }
    },
    onSearch() {
      this.items = [];
      this.isLoading = true;
      this.selectedNegRows = [];
      this.selectedPosRows = [];
      // prepare the month-picker dates
      // let s = fromFirstDay(this.filter.byCalendar.from);
      // let e = toLastDay(this.filter.byCalendar.to);

      let brId = this.filter.byRegistry.id;
      // se è selezionato il gruppo: lascio visibile il registry ma non invio filter.byRegistry.id
      if (this.filter.byRegistryGroup.id) {
        // this.resetRegistryId();
        this.filter.byRegistry.id = null;
      }
      // se ci sono le relazioni (byRegistryRegistry), metto gli id in byRegistry
      let brrId = this.byRegistryRegistry.id;
      if (brrId && brrId.length) {
        this.filter.byRegistry.id = [brId];
        for (let rId of this.byRegistryRegistry.id) {
          this.filter.byRegistry.id.push(rId);
        }
        // brrId = this.byRegistryRegistry.id;
        this.byRegistryRegistry.id = null;
      }
      // let criteria = Object.assign({}, this.filter);
      // escludo byCalendar perché lo usa solamente index3
      let { byCalendar, ...criteria } = this.filter;
      // set the calculated form values
      // TODO: byCalendar solo per la index 3
      let calendar = fromLastDayPreviousMonth(byCalendar.to); //TODO aggiungere dal giorno precedente a firstDay

      // prepare the queryString
      let qs = {};
      // noPagination
      qs.pagination = "perPage=0";
      if (this.table.filterOn && Object.keys(this.table.filterOn).length) {
        let filterOn = prepareFilterQueryString(this.table.filterOn);
        if (filterOn) {
          qs.filterOn = filterOn;
        }
      }
      let criteria1 = { ...criteria };
      // criteria1.byView = "deferred";
      criteria1.byDeferred = "yes";
      // DEBUG
      // for (let i of [1, 2, 3, 4]) {
      //   console.debug(`criteria${i}: `, eval(`criteria${i}`));
      // }

      let criteria2 = { ...criteria };
      // DEBUG: replace with the correct filter by???
      criteria2.byDeposited = "yes";

      let criteria3 = { ...criteria };
      criteria3.byAttribute = {};
      criteria3.byAttribute.payment_type = [1, 2];
      criteria3.byCalendar = {};
      criteria3.byCalendar.from = calendar;
      criteria3.byCalendar.to = calendar;

      let criteria4 = { ...criteria };
      criteria4.byAttribute = {};
      criteria4.byAttribute.status_payment = 0;

      // ripristino i magheggi fatti per le "sparizioni" di byRegistryRegistry...
      this.byRegistryRegistry.id = brrId;
      // this.filter.byRegistry.id = brId; // CHECK BOH!? perchè poi criteria1 non ha tutti i registry???

      let qs1 = { ...qs };
      let qs2 = { ...qs };
      let qs3 = { ...qs };
      let qs4 = { ...qs };

      let filter1 = prepareFilterQueryString(criteria1);
      if (filter1) {
        qs1.filter = filter1;
      }
      let filter2 = prepareFilterQueryString(criteria2);
      if (filter2) {
        qs2.filter = filter2;
      }
      let filter3 = prepareFilterQueryString(criteria3);
      if (filter3) {
        qs3.filter = filter3;
      }
      let filter4 = prepareFilterQueryString(criteria4);
      if (filter4) {
        qs4.filter = filter4;
      }
      //   La index sarà il merge di 4 index:
      // - 1. book_entry con un filtro residuo sospeso > 0 (byDeferred)
      // - 2. book_entry con un filtro nuovo: acconto residuo > 0 (by???)
      // - 3. insurance_payment (quetanzamenti) di tipo 1 e 2 (byAttribute[payment_type]=1,2) + Fino A ByCalendar.to
      // => prendere insurance_ancillary
      // - 4. assegni postdatati (N/A) (tutti quelli non incassati)

      let queryString1 = Object.values(qs1).join("&");
      let queryString2 = Object.values(qs2).join("&");
      let queryString3 = Object.values(qs3).join("&");
      let queryString4 = Object.values(qs4).join("&");

      let Repo1 = RepositoryFactory.get(this.repository);
      let Repo2 = RepositoryFactory.get(this.repository);
      let Repo3 = RepositoryFactory.get("insurance_payment");
      let Repo4 = RepositoryFactory.get("unsolved_payment");

      const i1 = Repo1.index(queryString1);
      const i2 = Repo2.index(queryString2);
      const i3 = Repo3.index(queryString3);
      const i4 = Repo4.index(queryString4);

      Promise.all([i1, i2, i3, i4])
        .then((values) => {
          // set the itemX variables
          for (const [index, value] of values.entries()) {
            this[`items${index + 1}`] = value.data.data;
          }
          this.items = mergeIndex(
            this.items1,
            this.items2,
            this.items3,
            this.items4
          );
          // add check prop to data
          this.items = this.items.map((obj) => ({ ...obj, check: true }));
          this.negIds = this.items
            .filter((e) => e.type === "S")
            .map((e) => e.id);
          this.posIds = this.items
            .filter((e) => e.type === "A")
            .map((e) => e.id);

          this.model = {};
          for (const item of this.items) {
            let {
              id,
              book_entry_id,
              money,
              type,
              enabled,
              check /*, ...rest*/,
            } = item;
            this.$set(this.model, id, {
              id,
              book_entry_id,
              check,
              type,
              money,
              enabled,
            });

            if (item.type === "S") {
              this.selectedNegRows = this.pushSortedIds(
                item.id,
                this.selectedNegRows,
                this.negIds
              );
            }
            if (item.type === "A") {
              this.selectedPosRows = this.pushSortedIds(
                item.id,
                this.selectedPosRows,
                this.posIds
              );
            }
          }
          let s = this.semaphore(
            this.selectedNegRows,
            this.selectedPosRows,
            this.model
          );
          let e = this.elaborate(
            this.selectedNegRows,
            this.selectedPosRows,
            this.model
          );
          this.$set(this, "sem", null);
          this.$set(this, "sem", s);
          this.$set(this, "ela", null);
          this.$set(this, "ela", e);
          this.myToggle = true;
          this.isLoading = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
          this.isLoading = false;
        })
        .finally(() => {
          // this.isLoading = false;
          // ripristino
          this.filter.byRegistry.id = brId;
        });
    },
    onClearFilter() {
      this.resetRegistryId();
      this.resetFilter();
    },
    openQuickSearchRegistry() {
      this.filter.byRegistry.id = null;
      this.$bvModal.show("quickSearchRegistryModal");
    },
    handleRegistryModalInput(value) {
      this.filter.byRegistry.id = value.id;
      this.registry_data = value;
      this.form.input_label_registry = this.toInfoData(
        this.registry_data,
        "registryfullname"
      );
      this.$bvModal.hide("quickSearchRegistryModal");
      this.loadRegistryGroupsByRegistry(value.id);
      this.showGroups = true;
      this.showRelations = true;
    },
    resetRegistryId() {
      this.registry_data = null;
      this.form.input_label_registry = null;
      this.filter.byRegistry.id = null;
      this.filter.byRegistryGroup.id = null;
      this.byRegistryRegistry.id = null;
      this.opt_groups = null;
      this.opt_relations = null;
      this.showGroups = false;
      this.showRelations = false;
    },
    loadRegistryGroupsByRegistry(id = null) {
      if (!id) {
        id = this.filter.byRegistry.id;
      }
      let Repo = RepositoryFactory.get("registry");
      let relationString = "relations/byRegistry/byRegistryGroup";
      Repo.show(id, relationString)
        .then((response) => {
          // console.log(response.data.data);
          this.opt_groups = response.data.data.registry_groups.map((e) => ({
            value: e.id,
            text: e.code,
          }));
          // se il parametro di agenzia lo prevede, seleziono di fefault i gruppo principale
          // TODO get the parameter!!!
          let agency_parameter = false;
          if (agency_parameter && this.opt_groups.length) {
            let found = response.data.data.registry_groups.find(
              (e) => e.pivot.primary === "Y"
            );
            if (found) {
              this.filter.byRegistryGroup.id = found.pivot.registry_group_id;
            }
          }
          this.opt_relations = response.data.data.registries.map((e) => ({
            value: e.id,
            text: `${toRelationString("registries", e, [
              "VIRTUAL_FULLNAME",
            ])} - ${e.pivot.relative_type.formatted_title}`,
          }));
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.title {
  margin-left: 14px;
  margin-right: 5px;
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
.label {
  margin-left: 5px;
  margin-right: 5px;
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
.sem-green {
  width: 15px;
  height: 15px;
  background: rgba(0, 255, 0, 0.4);
  margin-left: 18px;
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
.sem-yellow {
  width: 15px;
  height: 15px;
  background: rgba(251, 255, 0, 0.4);
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
.sem-red {
  width: 15px;
  height: 15px;
  background: rgba(255, 0, 0, 0.4);
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
.check-green {
  width: 15px;
  height: 15px;
  background: radial-gradient(#56c456, white);
  margin-left: 18px;
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
.check-red {
  width: 15px;
  height: 15px;
  background: radial-gradient(#af545186, white);
  padding: 0;
  margin-top: 8px;
  font-size: 12px;
}
</style>
