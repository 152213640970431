<script>
import {
  // mergeIndex,
  pushSortedIds,
  semaphore,
  elaborate,
  getSemaphoreColor,
  getElaborateColor,
} from "@/utils/advance-payments";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { today } from "@/utils/dates";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
import Sesana from "@/components/accounting/advance-payment/table";
export default {
  mixins: [CustomFiltersMixin, ConfirmModalMixin],
  components: {
    Sesana,
  },
  methods: {
    pushSortedIds,
    semaphore,
    elaborate,
    getSemaphoreColor,
    getElaborateColor,
    onToggleSelectAll() {
      this.isToggling = true;
      // console.log(
      //   "onToggleSelectAll",
      //   Object.keys(this.model).length,
      //   ", myToggle: ",
      //   this.myToggle
      // );
      for (const key of Object.keys(this.model)) {
        this.$set(this.model[key], "check", this.myToggle);
        this.handleSelectRow(this.myToggle, this.model[key]);
      }
      this.refreshColors();
      this.isToggling = false;
    },
    onSelectRow(event, index, item) {
      this.handleSelectRow(event, item);
      this.refreshColors();
    },
    handleSelectRow(event, item) {
      if (event) {
        // push
        if (item.type === "S") {
          this.selectedNegRows = pushSortedIds(
            item.id,
            this.selectedNegRows,
            this.negIds
          );
        }
        if (item.type === "A") {
          this.selectedPosRows = pushSortedIds(
            item.id,
            this.selectedPosRows,
            this.posIds
          );
        }
      } else {
        // pop
        if (item.type === "S") {
          this.selectedNegRows = this.selectedNegRows.filter(
            (i) => i !== item.id
          );
        }
        if (item.type === "A") {
          this.selectedPosRows = this.selectedPosRows.filter(
            (i) => i !== item.id
          );
        }
      }
    },
    refreshColors() {
      let s = semaphore(this.selectedNegRows, this.selectedPosRows, this.model);
      let e = elaborate(this.selectedNegRows, this.selectedPosRows, this.model);
      this.$set(this, "sem", null);
      this.$set(this, "sem", s);
      this.$set(this, "ela", null);
      this.$set(this, "ela", e);
    },
    getTreasuryIdByCode(code) {
      let found = this.$store.state.auth.treasuries.find(
        (e) => e.code === code
      );
      if (found) {
        return found.id;
      }
      return null;
    },
    createUseDetail(book_entry_id, gross) {
      // let id;
      // // TODO: call BE to store and return the newly created id for the ACCONTO
      // id = Math.round(Math.random() * 1000);
      // console.log(
      //   `createUseDetail: book_entry_id: ${book_entry_id}, amount: ${gross}, new id: ${id}`
      // );
      // return new Promise((resolve) => {
      //   setTimeout(() => {
      //     resolve({ data: { data: { id } } });
      //   }, 1000);
      // });
      let detail_date = today();
      let treasury_id = this.getTreasuryIdByCode("UC");
      let payload = {
        book_entry_id,
        treasury_id,
        gross,
        detail_date,
      };
      const Repo = RepositoryFactory.get("entry_detail");
      return Repo.store(payload);
    },
    createDefDetail(book_entry_id, gross) {
      // let id;
      // // TODO: call BE to store and return the newly created id for the SOSPESO
      // id = Math.round(Math.random() * 1000);
      // console.log(
      //   `createDefDetail: book_entry_id: ${book_entry_id}, amount: ${gross}, new id: ${id}`
      // );
      // return new Promise((resolve) => {
      //   setTimeout(() => {
      //     resolve({ data: { data: { id } } });
      //   }, 1000);
      // });
      let detail_date = today();
      let treasury_id = this.getTreasuryIdByCode("SS");
      let payload = {
        book_entry_id,
        treasury_id,
        gross,
        detail_date,
      };
      const Repo = RepositoryFactory.get("entry_detail");
      return Repo.store(payload);
    },
    createAccDetail(book_entry_id, gross) {
      // let id;
      // // TODO: call BE to store and return the newly created id for the SOSPESO
      // id = Math.round(Math.random() * 1000);
      // console.log(
      //   `createAccDetail: book_entry_id: ${book_entry_id}, amount: ${gross}, new id: ${id}`
      // );
      // return new Promise((resolve) => {
      //   setTimeout(() => {
      //     resolve({ data: { data: { id } } });
      //   }, 1000);
      // });
      let detail_date = today();
      let treasury_id = this.getTreasuryIdByCode("AC");
      let payload = {
        book_entry_id,
        treasury_id,
        gross,
        detail_date,
      };
      const Repo = RepositoryFactory.get("entry_detail");
      return Repo.store(payload);
    },
    createPivotDetail(left, right) {
      // console.log(`createPivotDetail: left: ${left}, right: ${right}`);
      // return new Promise((resolve) => {
      //   setTimeout(() => {
      //     resolve(true);
      //   }, 1000);
      // });
      let payload = {
        entry_detail: {
          [right]: {},
        },
      };
      const Repo = RepositoryFactory.get("entry_detail");
      return Repo.pivot_store(left, "entry_detail", payload);
    },
    onSubmit() {
      // TODO confirm (Sei)
      this.showConfirm({
        yesCallback: () => {
          this.submit();
        },
        // noCallback: () => {
        //   this.$bvModal.hide("createInsurancePaymentOptionsModal");
        // },
        noCallback: null,
        title: "Conferma",
        message:
          "Confermi di voler recuperare i sospesi con gli acconti selezionati?",
        yesLabel: "PROCEDI",
        yesVariant: "outline-lisaweb",
        noLabel: "ANNULLA",
        // yesVariant:
        // noVariant:
        // headerBgVariant:
      });
    },
    submit() {
      if (this.ela && Object.keys(this.ela).length) {
        this.isLoading = true;
        let def_detail = {};
        let acc_details = {};
        let useIds = {};
        let defId;
        let promises = {};
        for (const [e, elaboration] of this.ela.entries()) {
          promises[e] = [];
          useIds[e] = [];
          defId = null;
          def_detail[e] = elaboration.def_detail;
          acc_details[e] = elaboration.acc_details;
          // c'è 1 solo def_detail
          const [book_entry_id, detail] = Object.entries(def_detail[e])[0];
          // LEX 19/01/2023: viene inviato (dalla submit) un payload senza gross
          // sembrerebbe che ci sono entry details con cassa 3 a 0.. provo a fixare in elaborate()
          for (const use of detail.uses) {
            promises[e].push(this.createUseDetail(book_entry_id, use.money));
          }
          Promise.all(promises[e])
            .then((values) => {
              // set the itemX variables
              for (const value of values) {
                let id = value.data.data.id;
                useIds[e].push(id);
              }
              this.createDefDetail(book_entry_id, detail.def.money)
                .then((response) => {
                  defId = response.data.data.id;
                  // pivot (non aspetto risposta)
                  for (let useId of useIds[e]) {
                    // this.createPivotDetail(useId, defId); // fix 10.02.2023
                    this.createPivotDetail(defId, useId);
                  }
                  // ci possono essere più acc_detail
                  for (let [index, obj] of Object.entries(acc_details[e])) {
                    const [book_entry_id, detail] = Object.entries(obj)[0];
                    this.createAccDetail(book_entry_id, detail.acc.money)
                      .then((response) => {
                        let accId = response.data.data.id;
                        // pivot
                        // this.createPivotDetail(useIds[e][index], accId);
                        this.createPivotDetail(accId, useIds[e][index]);
                        // CHECK è orse questo il punto per capire se ha finito la chain di promise???
                        if (e == Object.keys(this.ela).length - 1) {
                          this.isLoading = false;
                          this.$showSnackbar({
                            preset: "success",
                            text: "Recupero eseguito con successo",
                          });
                          this.$set(this, "sem", {});
                          this.$set(this, "ela", {});
                          this.onSearch();
                        }
                      })
                      .catch((error) => {
                        let errMsg = this.$getErrorMessage(error);
                        this.$showSnackbar({
                          preset: "error",
                          text: errMsg,
                        });
                        this.isLoading = false;
                      });
                  }
                })
                .catch((error) => {
                  let errMsg = this.$getErrorMessage(error);
                  this.$showSnackbar({
                    preset: "error",
                    text: errMsg,
                  });
                  this.isLoading = false;
                });
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: errMsg,
              });
              this.isLoading = false;
            });
        }
      } else {
        // TODO add message
        this.$showSnackbar({
          preset: "info",
          text: "Non ci sono recuperi",
        });
      }
    },
    toSemColor(id) {
      if (this.sem[id] && this.selectedNegRows.find((e) => e == id)) {
        return getSemaphoreColor(this.sem[id].state);
      }
      return getSemaphoreColor(null);
    },
    toElaColor(id) {
      if (this.ela[id] && this.selectedPosRows.find((e) => e == id)) {
        return getElaborateColor(this.ela[id].empty, this.ela[id].used);
      }
      return getElaborateColor(null, null);
    },
  },
  computed: {
    // isTgglng: {
    //   set(val) {
    //     // this.isToggling = val;
    //     this.$set(this, "isToggling", val);
    //   },
    //   get() {
    //     return this.isToggling;
    //   },
    // },
  },
};
</script>
